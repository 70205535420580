import React from 'react'
import {Link} from 'gatsby'

import {news} from '../../assets/news/news_feed'

import './news-feed.component.less'
import {useTranslation} from "react-i18next";

const scrollFeed = (left, top) => {
    document.querySelector('.news_feed').scrollTo({
        top,
        left,
        behavior: "smooth"
    })
}

const arrowLeftClick = () => {
    const elem = document.querySelector('.news_feed');
    scrollFeed(elem.scrollLeft - 938);
}

const arrowRightClick = () => {
    const elem = document.querySelector('.news_feed');
    scrollFeed(elem.scrollLeft + 938);
}

class NewsFeedItems extends React.Component {
    componentDidMount = () => {
        // const elem = document.querySelector('.news_item-active');
        // if (elem) {
        //     scrollFeed(elem.offsetLeft, elem.offsetTop)
        // }

        // window.addEventListener('scroll', function(e) {
        //     console.log(window.scrollY, document.body.scrollHeight - window.innerHeight)
        //
        //     const percent = window.scrollY / (document.body.scrollHeight - window.innerHeight);
        //
        //     const feed = document.querySelector('.news_feed');
        //     feed.scrollTop = feed.scrollHeight * percent;
        // });
    };

    render = () => news.map((n, i) => {
        return (
            <Link className={'news_item ' + (n.link ? '' : 'news_item-no_link')}
                  activeClassName="news_item-active"
                  key={i} to={n.link ? n.link + '/' : ''}
            >
                <div className="news_item__label">
                    <div className="news_item__title">{n.title}</div>
                    <div className="news_item__date">{n.date}</div>
                </div>
                <img src={require(`../../assets/news/img_feed/${n.img}`).default} alt=""/>
                <p className="news_item__image-description">*Photos are for illustration purposes only and are not actual photos of the project</p>
                <div className="news_item__text">
                    {n.text}
                    {n.link ? <span className="news_item__more"> more</span> : ''}
                </div>
            </Link>
        )
    });

}

export default function NewsFeed() {
    const {t} = useTranslation();

    return <div>
        <div  className="news_feed__wrapper">
            <div className="news_feed__title_horizontal" dangerouslySetInnerHTML={{__html: t("articlesNews")}} />
            <div className={'news_feed__arrow news_feed__arrow-left'}
                 onMouseDown={arrowLeftClick}></div>
            <div className={'news_feed__arrow news_feed__arrow-right'}
                 onMouseDown={arrowRightClick}></div>
        </div>

        <div className="news_feed">
            <div className="news_feed__title" dangerouslySetInnerHTML={{__html: t("articlesNews")}}/>
            <NewsFeedItems/>
        </div>
    </div>
}
