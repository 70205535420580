export const news = [
    {
      title: "NEWS",
      date: "21 Feb 2022",
      img: "whoosh-and-cityair-launched-kick-scooters-with-air-quality-sensors.jpg",
      link: "/news/whoosh-and-airvoice-launched-kick-scooters-with-air-quality-sensors",
      text: `Whoosh and Airvoice launched kick scooters with air quality sensors`
    },
    {
      title: "CUSTOMER CASE",
      date: "",
      img: "vtu.jpg",
      link: "/dust-dispersion-control-solution-for-coal-transloading-terminal",
      text: `How trajectory modelling and Airvoice monitoring networks handle the issues in stevedore industry`
    },
    {
      title: "CUSTOMER CASE",
      date: "",
      img: "sakhalin.jpg",
      link: "/air-quality-monitoring-network-in-the-pacific-islands",
      text: `Air quality monitoring network in the Pacific Islands`
    },

    {
      title: "CUSTOMER CASE",
      date: "",
      img: "krasnoyarsk.jpg",
      link: "/how-scientific-monitoring-network-operaties-in-one-of-the-most-polluted-cities-in-the-world",
      text: `How scientific monitoring network operaties in one of the most polluted cities in the world`
    },

    {
      title: "MOMENTS",
      date: "",
      img: "empty_news.jpg",
      link: "",
      text: `2021. First Air Quality Monitor with an alternative energy source was installed in Pacific. It provides Air Quality Monitoring for Stevedore Company`
    },

    {
      title: "MOMENTS",
      date: "",
      img: "moment_1.jpg",
      link: "",
      text: `2021. Scientific School Students come to visit Airvoice Hardware Team`
    },

    {
      title: "MOMENTS",
      date: "",
      img: "moment_2.jpg",
      link: "",
      text: `2015. Future Airvoice founders  assembled first Monitor, had it
      drone mounted and tried to identify air pollutants concentration and altitude dependence. This is how the prototype looked like.`
    },

    {
      title: "NEWS",
      date: "2 Mar 2021",
      img: "empty_news_2.jpeg",
      link: "",
      text: `Airvoice announced launch of Airvoice.Plume — new ultimate tool for industries`
    },

    {
      title: "MOMENTS",
      date: "",
      img: "moment_3.jpg",
      link: "",
      text: `2019. Airvoice team has presented latest solutions and techniques at the world's largest technology conference Web Summit in Lisbon`
    },

    {
      title: "MOMENTS",
      date: "",
      img: "moment_4.jpg",
      link: "",
      text: `2021. Airvoice together with friends and partners from 23 cities in 10 countries have designed a hoodie and marked it with
      immortal and so relevant for pandemic year JFK’s statement “We all breathe the same air”. Every participant got a hoodie.`
    },
]
