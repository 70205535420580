/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import TopArtefact from '../assets/img/icon/top_artefact.svg'

import HeaderComponent from './header/header.component'
import FooterComponent from './footer/footer.component'
import NewsFeed from './news-feed/news-feed.component'

import './layout.less'
import './common_style.less'

const Layout = ({ children }) => {

    if (typeof document !== `undefined`) {
        setTimeout(() => {
            document.getElementById('main_hide').classList.add('main_hide__show')
        }, 500)
    }

    return (
        <div id="main_hide">
            <img className="top_artefact" src={TopArtefact} alt="" />
            <HeaderComponent />
            <div style={{
                    margin: `0 auto`,
                    paddingTop: 0,
                }}
            >
                <main>{children}</main>
            </div>

            <NewsFeed/>

            <FooterComponent/>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
