import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

import './footer.component.less'

import linkedin from '../../assets/img/footer/linkedin.svg'
import Youtube from '../../assets/img/footer/youtube.svg'
import moment from "moment";

const FooterComponent = () => {
    const { i18n, t } = useTranslation()

    return (
        <div>
            <div className="container">
                <div className="footer">
                    <div className="footer_content">
                        <div className="footer_contact">
                            <div className="footer_contact__ca">CA</div>
                            <div className="footer_contact__items">
                                <a href="mailto:hello@airvoice.global" className="footer_contact__mail">
                                    hello@airvoice.global
                                    <span className="footer_contact__flag footer_contact__flag-usa"></span>
                                </a>
                                <a href={`tel:+12028309898`} className="footer_contact__tel">+1 202 830 98 98</a>
                                <div className="footer_contact__address">919 North market street <br/>Suite 950, <br/>Wilmington,
                                    DE 18901
                                </div>
                            </div>
                            <div className="footer_contact__items">
                                <a href="mailto:namaste@airvoice.global" className="footer_contact__mail">
                                namaste@airvoice.global
                                    <span className="footer_contact__flag footer_contact__flag-india"></span>
                                </a>
                                <a href="tel:+917683045636" className="footer_contact__tel">+91 768 304 5636</a>
                                <div className="footer_contact__address">74, Royalton Tower, Princeton <br/>Estate,
                                    Club Drive, DLF Phase V, <br/>Gurugram, Haryana-122009
                                </div>
                            </div>
                        </div>
                        <div className="footer_link-wrapper">
                            <div className="footer-menu-wrapper">
                                <ul className="footer-menu">
                                    <li>
                                        <Link to="/platform/" language={i18n.language} className="footer-menu__link">
                                            <div dangerouslySetInnerHTML={{__html: t("platformMenuItem")}}/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/equipment/" language={i18n.language} className="footer-menu__link">
                                            <div dangerouslySetInnerHTML={{__html: t("monitors")}}/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/modeling/" language={i18n.language} className="footer-menu__link">
                                            <div dangerouslySetInnerHTML={{__html: t("modelingMenuItem")}}/>
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://airvoice.global/" target="_blank">
                                            <div className="footer-menu__link"
                                                 dangerouslySetInnerHTML={{__html: t("IAQ")}}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-menu-wrapper">
                                <ul className="footer-menu">
                                    <li>
                                        <Link to="/who-we-are/" language={i18n.language} className="footer-menu__link">
                                            <div dangerouslySetInnerHTML={{__html: t("whoWeAre")}}/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/connect-with-us/" language={i18n.language}
                                              className="footer-menu__link">
                                            <div dangerouslySetInnerHTML={{__html: t("contacts")}}/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="right_block">
                                <div className="footer-socserv">
                                    <a target="_blank" href="https://www.linkedin.com/company/airvoice-avms"><img
                                        src={linkedin} alt="linkedin"/></a>
                                    <a target="_blank"
                                       href="https://www.youtube.com/@AirVoice_AVMS"><img
                                        src={Youtube} alt="YouTube"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        ©{moment().get('year')}
                        <span dangerouslySetInnerHTML={{__html: t("allRight")}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

FooterComponent.propTypes = {}

FooterComponent.defaultProps = {}

export default FooterComponent
