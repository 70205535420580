import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'
import cx from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler';
import {useI18next} from "gatsby-plugin-react-i18next";

import './header.component.less'

import Artifact from '../../assets/img/logo/artifact.png'


const HeaderComponent = () => {
    const { i18n, t } = useTranslation()

    const [isMobileMenuOpened, setMobileMenuState] = useState(false)

    const [isScrolled, setHeaderFloating] = useState(false)

    const { originalPath } = useI18next()
    let isOpenDropDownMenu = false;

    const openMobileMenu = (stateMenu) => {
        setMobileMenuState(stateMenu);
        document.body.style.overflowY = !isMobileMenuOpened && stateMenu ? 'hidden' : 'auto';
    }

    const openSubPageMenu = (stateMenu) => {
        setMobileMenuState(stateMenu);
        document.body.style.overflowY = 'auto';
    }

    useEffect(() => {
        const triggerDistance = window.innerWidth > 576 ? 50 : 20

        const updateHeader = () => {
            setHeaderFloating(window.scrollY > triggerDistance)
        }

        updateHeader()

        window.addEventListener('scroll', updateHeader)

        return () => {
            window.removeEventListener('scroll', updateHeader)
        }
    }, [])

    useEffect(() => {
        let idMenu = originalPath
        if (originalPath === '/equipment/' || originalPath === '/platform/' || originalPath === '/modeling/') {
                     idMenu.slice(1, -1) === '' ? document.getElementById('monitoring-networks').classList.add('menu__dropdown_str-active') : document.getElementById(idMenu.slice(1, -1)).classList.add('menu__dropdown_str-active')
        }
            if (originalPath === '/equipment/' || originalPath === '/platform/' || originalPath === '/modeling/') {
                if (!document.getElementById('menu__button').classList.contains('menu__button-active')) {
                    document.getElementById('menu__button').classList.add('menu__button-active')
                }
            } else {
                if (document.getElementById('menu__button').classList.contains('menu__button-active'))
                    document.getElementById('menu__button').classList.remove('menu__button-active')
            }
    });

    function openDropdownMenu () {
        isOpenDropDownMenu = !isOpenDropDownMenu;
        isOpenDropDownMenu ? document.getElementById('arrow_down').classList.add('arrow_down-active') : document.getElementById('arrow_down').classList.remove('arrow_down-active');
        isOpenDropDownMenu ? document.getElementById('menu__dropdown').classList.add('menu__dropdown-active') : document.getElementById('menu__dropdown').classList.remove('menu__dropdown-active');
    }

    function closeDropdownMenu () {
        isOpenDropDownMenu = false;
        if (document.getElementById('arrow_down').classList.contains('arrow_down-active')) document.getElementById('arrow_down').classList.remove('arrow_down-active');
        if (document.getElementById('menu__dropdown').classList.contains('menu__dropdown-active')) document.getElementById('menu__dropdown').classList.remove('menu__dropdown-active');
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
    return (
        <div>
            <div className={cx('background_for_menu', { 'background_for_menu-active': isMobileMenuOpened })}/>
            <div className={cx('main_header wrapper_block__block wrapper_block__block--no-border', { out: isScrolled })}>
                <Link onClick={() => openMobileMenu(false)} to="/" language={i18n.language} className="logo logo-mobile"/>
                <div
                    className={cx('menu-btn', { open: isMobileMenuOpened })}
                    role="button"
                    onClick={() => openMobileMenu(!isMobileMenuOpened)}
                >
                    <div className="menu-btn__burger"/>
                </div>
                <div className={cx('container', 'main_header__container', { 'main_header__container-active': isMobileMenuOpened })}>
                    <Link
                        to="/"
                        language={i18n.language}
                        className="logo logo-desktop"
                    />
                    <div className="menu menu-left">
                        <Link
                            to="/"
                            language={i18n.language}
                            onClick={() => openMobileMenu(false)}
                            activeClassName="menu__dropdown_str-active"
                        >
                            <div className="menu__item">{t("main")}</div>
                        </Link>
                        <OutsideClickHandler onOutsideClick={() => closeDropdownMenu()}>
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div className="menu__item menu__item_display-desktop" onClick={()=>openDropdownMenu()}>
                                <div className="menu__button" id="menu__button">
                                    {t("ambientAirQuality")}
                                    <div id="arrow_down" className="arrow_down">
                                        <svg width="11" height="6" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5 7.68046L2.31679 0.402728C1.78679 -0.134243 0.927495 -0.134243 0.397497 0.402728C-0.1325 0.939699 -0.1325 1.8103 0.397498 2.34727L8.54035 10.5973C9.07035 11.1342 9.92965 11.1342 10.4596 10.5973L18.6025 2.34727C19.1325 1.8103 19.1325 0.939698 18.6025 0.402727C18.0725 -0.134244 17.2132 -0.134244 16.6832 0.402727L9.5 7.68046Z" fill="#000"/>
                                        </svg>
                                    </div>
                                </div>
                                <div id="menu__dropdown" className="menu__dropdown" >
                                    <Link 
                                        id="platform" 
                                        onClick={() => openSubPageMenu(true)}
                                        activeClassName="menu__dropdown_str-active"
                                        to="/platform/"
                                        language={i18n.language}
                                    >
                                        <div className="menu__dropdown_str">{t("platformMenuItem")}</div>
                                    </Link>
                                    <Link 
                                        id="modeling" 
                                        onClick={() => openSubPageMenu(true)}
                                        activeClassName="menu__dropdown_str-active"
                                        to="/modeling/"
                                        language={i18n.language}
                                    >
                                        <div className="menu__dropdown_str">{t("modelingMenuItem")}</div>
                                    </Link>
                                    <Link 
                                        id="equipment" 
                                        onClick={() => openSubPageMenu(true)}
                                        activeClassName="menu__dropdown_str-active"
                                        to="/equipment/"
                                        language={i18n.language}
                                    >
                                        <div className="menu__dropdown_str">{t("monitors")}</div>
                                    </Link>
                                </div>
                            </div>
                        </OutsideClickHandler>

                        <Link 
                            id="platform" 
                            onClick={() => openSubPageMenu(true)}
                            activeClassName="menu__dropdown_str-active"
                            to="/platform/"
                            language={i18n.language}
                        >
                            <div className="menu__item menu__item_display-mobile">{t("platformMenuItem")}</div>
                        </Link>
                        <Link 
                            id="modeling" 
                            onClick={() => openSubPageMenu(true)}
                            activeClassName="menu__dropdown_str-active"
                            to="/modeling/"
                            language={i18n.language}
                        >
                            <div className="menu__item menu__item_display-mobile">{t("modelingMenuItem")}</div>
                        </Link>
                        <Link 
                            id="equipment" 
                            onClick={() => openSubPageMenu(true)}
                            activeClassName="menu__dropdown_str-active"
                            to="/equipment/"
                            language={i18n.language}
                        >
                            <div className="menu__item menu__item_display-mobile">{t("monitors")}</div>
                        </Link>
                        <a href="https://indoor.airvoice.global/" target="_blank">
                            <div className="menu__item" dangerouslySetInnerHTML={{__html: t("IAQ")}} />
                        </a>
                        <Link
                            to="/who-we-are/"
                            language={i18n.language}
                            onClick={() => openMobileMenu(false)}
                            activeClassName="menu__dropdown_str-active"
                        >
                            <div className="menu__item" dangerouslySetInnerHTML={{__html: t("whoWeAre")}} />
                        </Link>
                        <Link
                            to="/connect-with-us/"
                            language={i18n.language}
                            onClick={() => openMobileMenu(false)}
                            activeClassName="menu__dropdown_str-active"
                        >
                            <div className="menu__item" dangerouslySetInnerHTML={{__html: t("contacts")}} />
                        </Link>
                    </div>
                    <div className="menu__img">
                        <img alt="" src={Artifact} />
                    </div>
                    <div className="menu__mail">
                        <div>hello@<br/>cityair.global</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}

export default HeaderComponent
